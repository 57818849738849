import React, { useCallback } from 'react';
import type { Theme } from '@material-ui/core/styles';
import { produce } from 'immer';

import CustomThemeProvider from 'layouts/CustomThemeProvider';
import LoginLogo from 'components/LoginLogo';
import Notifications from 'sharedContainers/Notifications';
import learnCommunityOrganization from 'utils/DefaultOrganization';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import useStyles from './useStyles';

declare const gon: any;

const PreApplicationLayout = ({ children }: { children: React.ReactNode }) => {
  const organization = gon.currentOrganization || learnCommunityOrganization;

  const themeOverride = useCallback(
    (theme: Theme) => {
      const color = OrganizationPresenter.headerBackgroundColor(organization)!;
      const textColor = OrganizationPresenter.headerTextColor(organization)!;

      return produce(theme, draftTheme => {
        /* eslint-disable no-param-reassign */
        Object.assign(draftTheme.palette.primary, {
          main: color,
          contrastText: textColor,
          light: color,
        });
        draftTheme.palette.navbarItem.selected = color;
        /* eslint-enable no-param-reassign */
      });
    },
    [organization],
  );

  return (
    <CustomThemeProvider themeOverride={themeOverride}>
      <PreApplicationLayoutInner organization={organization}>{children}</PreApplicationLayoutInner>
    </CustomThemeProvider>
  );
};

/** Split out so that `useStyles()` runs under `<CustomThemeProvider>`. */
const PreApplicationLayoutInner = ({ children, organization }: { children: React.ReactNode; organization: any }) => {
  const classes = useStyles();
  return (
    <>
      <Notifications />
      <div className={classes.root}>
        <div className={classes.bar} />
        <div className={classes.body}>
          <div className={classes.logoContainer}>
            <LoginLogo organization={organization} />
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default PreApplicationLayout;
