import { Helmet } from 'react-helmet';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { pluck, includes } from 'ramda';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import * as Routes from 'Routes';

import Pardot from 'services/Pardot';
import { authenticityToken } from 'utils/FetchHelpers';
import UserPresenter from 'presenters/UserPresenter';
import TermsAndPrivacyLinks from 'components/TermsAndPrivacyLinks';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Form from 'forms/SignUp/SignUpForm';
import { set, isBlank } from 'utils/Utils';
import RoleSelector from '../sharedComponents/RoleSelector';
import roles from '../sharedComponents/RoleSelector/roles';
import styles from './styles';

class SignUpPage extends PureComponent {
  static propTypes = {
    user: UserPresenter.shape().isRequired,
    classes: PropTypes.shape().isRequired,
  };

  state = {
    form: Form.defaultAttributes(this.props.user),
    disabled: false,
  };

  componentDidMount() {
    const { search } = window.location;
    const primaryRole = queryString.parse(search).primary_role;

    this.setPrimaryRole(primaryRole);
  }

  setPrimaryRole = primaryRole => {
    if (isBlank(primaryRole)) {
      return;
    }

    const roleIds = pluck('id', roles);
    if (includes(primaryRole, roleIds)) {
      this.handleRoleChange(primaryRole);
      this.setState({ disabled: true });
    }
  };

  handleChange = name => e => {
    const newState = set(this.state, ['form', name], e.target.value);
    this.setState(newState);
  };

  handleRoleChange = role => {
    const newState = set(this.state, ['form', 'userType'], this.state.form.userType !== role ? role : '');
    this.setState(newState);
  };

  handleSubmit = () => {
    const params = Form.attributesToSubmit(this.state.form);
    Pardot.signUp(params);
  };

  render() {
    const {
      classes,
      user: { errors },
    } = this.props;
    const { form, disabled } = this.state;

    return (
      <>
        <Helmet>
          <title>Sign Up | Select Your Role</title>
        </Helmet>
        {form.userType && form.userType !== 'other' && (
          <Button
            color="transparent"
            size="xsmall"
            onClick={() => this.handleRoleChange('')}
            className={classes.backButton}
          >
            <ArrowBack className={classes.arrow} />
            Back
          </Button>
        )}
        <form
          action={Routes.user_registration_path()}
          method="post"
          onSubmit={this.handleSubmit}
          className={classes.form}
        >
          <input type="hidden" name="authenticity_token" value={authenticityToken()} />

          <div className={classes.root}>
            <Icon icon="welcome" />
            <h1 className={classes.title}>Join the Community!</h1>
            <p className={classes.description}>
              Please select the role that best describes your responsibilities at your organization.
            </p>
            <div className={classes.formContainer}>
              <RoleSelector
                disabled={disabled}
                user={form}
                onChange={this.handleChange}
                onChangeRole={this.handleRoleChange}
                buttonText="Create Account"
                errors={errors}
                showEmail
              />
            </div>
          </div>
        </form>
        <div className={classes.footer}>
          <div className={classes.signIn}>
            Already have an account?{' '}
            <Button variant="link" href={Routes.new_user_session_path()} className={classes.footerLink}>
              Sign In
            </Button>
          </div>
          <div className={classes.serviceLinks}>
            <TermsAndPrivacyLinks />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SignUpPage);
