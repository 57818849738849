import * as Yup from 'yup';
import { yupToFormErrors } from 'utils/Utils';
import { camelize, decamelize } from 'utils/keysConverter';

const StatusSchema = Yup.object().shape({
  firstName: Yup.string().required().label('First Name'),
  lastName: Yup.string().required().label('Last Name'),
  email: Yup.string().required().email().label('Email'),
  userType: Yup.string().required().label('User Type'),
});

export default {
  defaultAttributes(rawUser = {}) {
    const user = camelize(rawUser);

    return {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      userType: user.userType || '',
      email: user.email || '',
    };
  },

  validate(form) {
    try {
      StatusSchema.validateSync(form, { abortEarly: false });
      return {};
    } catch (err) {
      return yupToFormErrors(err);
    }
  },

  attributesToSubmit(form) {
    return decamelize({
      ...form,
      role: form.userType,
    });
  },
};
