import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import * as Routes from 'Routes';

import ConfirmationsRepository from 'repositories/ConfirmationsRepository';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const EmailConfirmation = ({ email }) => {
  const [showModal, updateShowModal] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Confirm Your Email</title>
      </Helmet>
      <Icon className={classes.icon} icon="email" />
      <div className={classes.title}>Welcome to LearnPlatform!</div>
      <p className={classes.text}>
        We sent a confirmation email to:
        <div className={classes.email} data-node="email">
          {email}
        </div>
        Check your email and click on the confirmation link to continue.
      </p>

      <p className={classes.hint}>
        Be sure to check your spam folder if you do not receive an email in the next 5 minutes!
      </p>
      <div className={classes.footer}>
        <Button
          className={classes.link}
          onClick={() => {
            ConfirmationsRepository.create({ email }).then(() => updateShowModal(true));
          }}
          variant="text"
          data-node="resend-email-button"
        >
          Resend Email
        </Button>
        <div>or</div>
        <Button variant="link" href={Routes.new_user_session_path()} className={classes.link}>
          Back to Login
        </Button>
      </div>
      {showModal && (
        <Modal
          onClose={() => {
            updateShowModal(false);
          }}
          open={showModal}
          buttonAlign="center"
          data-node="resend-email-modal"
        >
          <p className={classes.message}>
            An email has been re-sent to <span className={classes.email}>{email}</span>. Please make sure to check your
            spam folder if you do not receive an email in the next 5 minutes.
          </p>
        </Modal>
      )}
    </>
  );
};

EmailConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
};

export default EmailConfirmation;
