import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isBlank } from 'utils/Utils';

import defaultOrganization from 'utils/DefaultOrganization';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import styles from './styles';

class LoginLogo extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    organization: OrganizationPresenter.shape().isRequired,
  };

  render() {
    const { classes, organization } = this.props;
    const url = OrganizationPresenter.loginLogoUrl(organization);
    const fallbackUrl = OrganizationPresenter.loginLogoUrl(defaultOrganization);

    const loginLogoUrl = isBlank(url) ? fallbackUrl : url;

    return (
      <img
        src={loginLogoUrl}
        className={classes.root}
        alt={isBlank(url) ? 'LearnCommunity login logo' : `${OrganizationPresenter.name(organization)}'s logo`}
      />
    );
  }
}

export default withStyles(styles)(LoginLogo);
