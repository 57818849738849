import administratorImage from './images/administrator.svg';
import educatorImage from './images/educator.svg';
import providerImage from './images/provider.svg';

const roles = [
  {
    id: 'educator',
    title: 'Educator',
    image: educatorImage,
    descriptions: [
      'I am a teacher, faculty, adjunct or instructor',
      'I plan, prepare and deliver lessons to students',
      'I advise and collaborate with others in the development of teaching programs',
      'I support teaching, learning and educational technology professional development',
    ],
  },
  {
    id: 'administrator',
    title: 'Administrator',
    image: administratorImage,
    descriptions: [
      'I am a School, School District or Higher Ed Administrator or Leader',
      'I play a key role in my school’s resource and budgeting decisions',
      'I am a curriculum and instruction leader',
      'I am responsible for managing the technology systems that support students and teachers in my organization',
    ],
  },
  {
    id: 'product_developer',
    title: 'Provider',
    image: providerImage,
    descriptions: [
      'I sell education technology products and services for educators, students and/or administrators',
      'I am a product developer, responsible for designing and/or building edtech products at my company',
    ],
  },
];

export default roles;
